/* This CSS File is built to override the styles.css found in the public folder. */
p {
    white-space: pre-wrap;
}
a {
    text-decoration: none !important;
}
a:hover {
    text-decoration: none !important;
}
.item-info a {
    color: #00a1d9;
}
.content-container {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 500px;
}
.row {
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer {
    padding: 0;
}

/* To display the discount line properly in the pricing page (bookings) */
.bookingSummary em {
    display: block;
}